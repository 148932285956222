/**
 * All Value
 */
export const ALL_VALUE = 'All';

/**
 * All Value Parameter
 */
export const ALL_VALUE_PARAMETER = '$__all';

/**
 * No Value Parameter
 */
export const NO_VALUE_PARAMETER = '$__empty';
